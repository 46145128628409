import Cookies from 'universal-cookie'

import { isBrowser } from '../../lib/utils'

type RequestContext = {
  __TypeInfo: 'request'
  pageUrl: string
  pageTitle: string
  timezone: string
  userAgent: string
}

type UserContext = {
  __TypeInfo: 'user'
  emailAddress: string
}

type ThirdPartyContext = {
  __TypeInfo: 'thirdparty'
  heapSessionId: string
}

type MarketingContext = {
  __TypeInfo: 'marketing'
  source?: string
  medium?: string
  campaign?: string
  ttCampaignId?: string
  initialPage?: string
  initialReferrer?: string
}

type JSONValue = string | number | boolean | null | JSONValue[] | { [key: string]: JSONValue }

type EventToTrack = {
  event: string
  attributes: Record<string, JSONValue>
  user?: Omit<UserContext, '__TypeInfo'>
}

export class SignalClient {
  track({ event, attributes, user }: EventToTrack) {
    if (process.env.NEXT_PUBLIC_USER_SIGNALS_ENABLED !== 'true') return

    const data = {
      event: event,
      attributes: attributes,
      context: this.createContext({ user }),
      createdAt: new Date().toISOString(),
    }

    const url = process.env.NEXT_PUBLIC_CLIENT_API_BASE_URL + '/api/user-signal/v1/signals'

    const headers = new Headers({
      'content-type': 'application/json',
    })

    const authorization = new Cookies().get('figs-auth-token')
    if (authorization) {
      headers.append('Authorization', authorization)
    }

    fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    }).catch(error => console.error(error))
  }

  private createContext({ user }: { user?: Omit<UserContext, '__TypeInfo'> }) {
    const context = []

    if (user) {
      context.push(this.buildUserContext({ user }))
    }

    if (isBrowser()) {
      context.push(this.buildRequestContext())

      const marketingContext = this.buildMarketingContext()
      if (marketingContext) {
        context.push(this.buildMarketingContext())
      }

      const thirdpartyContext = this.buildThirdPartyContext()
      if (thirdpartyContext) {
        context.push(thirdpartyContext)
      }
    }

    return context
  }

  private buildRequestContext() {
    const requestContext: RequestContext = {
      __TypeInfo: 'request',
      pageTitle: document.title,
      pageUrl: document.URL,
      timezone: Intl?.DateTimeFormat().resolvedOptions().timeZone,
      userAgent: window.navigator.userAgent,
    }
    return requestContext
  }

  private buildUserContext({ user }: { user: Omit<UserContext, '__TypeInfo'> }) {
    const userContext: UserContext = {
      __TypeInfo: 'user',
      emailAddress: user.emailAddress,
    }
    return userContext
  }

  private buildThirdPartyContext() {
    let heapSessionId = null
    if (globalThis.heap?.getSessionId) {
      heapSessionId = globalThis.heap?.getSessionId()
    } else {
      const heapCookie = this.lookupHeapCookie()

      if (heapCookie) {
        heapSessionId = heapCookie.sessionId
      }
    }

    if (heapSessionId !== null) {
      const thirdpartyContext: ThirdPartyContext = {
        __TypeInfo: 'thirdparty',
        heapSessionId: heapSessionId,
      }
      return thirdpartyContext
    }
    return null
  }

  private buildMarketingContext() {
    const currentPage = document.URL
    const referrer = document.referrer
    const urlParams = new URLSearchParams(window.location.search)

    //If we see campaign information, update the session to get flagged as that campaign
    if (urlParams.get('utm_campaign') || urlParams.get('g_campaignid')) {
      const marketingContext: MarketingContext = {
        __TypeInfo: 'marketing',
        source:
          urlParams.get('utm_source') || (urlParams.get('g_campaignid') ? 'google' : null) || '',
        medium: urlParams.get('utm_medium') || urlParams.get('g_adtype') || '',
        campaign: urlParams.get('utm_campaign') || urlParams.get('g_campaignid') || '',
        ttCampaignId: urlParams.get('tt_campaignid') || '',
        initialPage: currentPage,
        initialReferrer: referrer,
      }
      sessionStorage.setItem('figsMarketingContext', JSON.stringify(marketingContext))
      return marketingContext
    }

    //If we have a previous campaign, use it. Otherwise check if it is organic or direct traffic
    const marketingContextString = sessionStorage.getItem('figsMarketingContext')
    if (marketingContextString) {
      const marketingContext = JSON.parse(marketingContextString)
      return marketingContext
    } else if (referrer !== null && referrer !== '' && !referrer.includes('wearfigs')) {
      const marketingContext: MarketingContext = {
        __TypeInfo: 'marketing',
        source: 'organic',
        initialPage: currentPage,
        initialReferrer: referrer,
      }
      sessionStorage.setItem('figsMarketingContext', JSON.stringify(marketingContext))
      return marketingContext
    } else {
      const marketingContext: MarketingContext = {
        __TypeInfo: 'marketing',
        source: 'direct',
        initialPage: currentPage,
        initialReferrer: referrer,
      }
      sessionStorage.setItem('figsMarketingContext', JSON.stringify(marketingContext))
      return marketingContext
    }

    return null
  }

  private lookupHeapCookie() {
    const heapCookieId = '_hp2_id.' + process.env.NEXT_PUBLIC_HEAP_APP_ID

    return new Cookies().get(heapCookieId)
  }
}
